.desc {
    padding: 24px 36px 36px;
    background: #fff;
    border-radius: 1px;
    border: 1px solid $color__title;
}

.desc__header {
    margin: 0 0 32px;
}

.desc__footer {
    
}
$font-family_default: 'Ruda', Arial, sans-serif;
$font-family_content: 'Open Sans', Arial, sans-serif;
$font-family_title: $font-family_default;
$font-size_default: 16px;

$line-height_default: 1;
$line-height_content: 1.4;

$color__default: #0F0E0E;
$color__title: #000;
$color__link: $color__default;

$color__brand: $color__default;
$color__border: $color__title;
$color__danger: #E75A1E;
$color__success: #3a960d;

$color__bg: #F4F4F4;
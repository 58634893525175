body {
  font: $font-size_default $font-family_default;
  line-height: $line-height_default;
  color: $color__default;
  background: $color__bg;
}

h1 {
  @extend %title-default;
}

a {
  @extend %link-default;

  &:hover,
  &:focus {
    @extend %link-default;
  }
}

input {
  outline: 0;
  outline: none;
}

button {
  outline: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
  }
}

.summ {
  white-space: nowrap;
}

.ms2_cost {
  white-space: nowrap;
}

@import '../../node_modules/swiper/swiper';
@import '~bootstrap/scss/bootstrap-reboot.scss';

@import 'tpl/variables';
@import 'tpl/mixins';
@import 'tpl/extends';
@import 'tpl/common';
@import 'tpl/mixes';

@import '~bootstrap/scss/utilities/flex';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/display';
@import '~bootstrap/scss/utilities/borders';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/grid';

@import 'blocks/header';
@import 'blocks/btn';
@import 'blocks/nav';
@import 'blocks/map';
@import 'blocks/pwd';
@import 'blocks/logo';
@import 'blocks/icons';
@import 'blocks/close';
@import 'blocks/panel';
@import 'blocks/search';
@import 'blocks/promo';
@import 'blocks/jumbotron';
@import 'blocks/section';
@import 'blocks/content';
@import 'blocks/divider';
@import 'blocks/pos';
@import 'blocks/desc';
@import 'blocks/cart';
@import 'blocks/overlay';
@import 'blocks/product';
@import 'blocks/infobox';
@import 'blocks/results';
@import 'blocks/contacts';
@import 'blocks/category';
@import 'blocks/prodlist';
@import 'blocks/notfound';
@import 'blocks/readmore';
@import 'blocks/recalculate';
@import 'blocks/footer';

@import 'blocks/form';
@import 'blocks/modal';
@import 'blocks/social';
@import 'blocks/swiper';
@import 'blocks/wrapper';
@import 'blocks/toggler';
@import 'blocks/promocode';
@import 'blocks/pagination';
